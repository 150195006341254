<template>
  <div class="wrapper">
    <div class="text" :class="show ? 'show' : ''">
      {{ word }}
    </div>
    <span class="toggle" @click="handleToggle" v-show="word && word.length > 50">{{ show ? $t("feture.collect"): $t("feture.expand") }}</span>
</div>
</template>

<script>
export default {
  name: 'word',
  props: ['word'],
  data() {
    return {
      show: false,
    }
  },
  methods: {
    handleToggle() {
      this.show = !this.show;
    }
  }
}
</script>

<style>
.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  border-radius: 8px;
  padding: 25px 0;
}

.text {
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: justify;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  position: relative;
}

.toggle {
  position: absolute;
  right: 5px;
  bottom: 0;
  width: 50px;
  margin-left: 10px;
  font-size: 16px;
  padding: 0 8px;
  background: #3F51B5;
  line-height: 24px;
  border-radius: 4px;
  color:  #fff;
  cursor: pointer;
}

.show {
  -webkit-line-clamp: 999;
}
</style>
