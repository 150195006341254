<template>
  <div
    v-loading="loading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
    style="width: 100%; height: 100%;"
  >
    <div class="root" v-if="state === 'SUCCESS'" :style="{height: `${getScreenHeight}px`}">
      <div class="top" v-if="mode === 'video'"></div>
      <div class="top" v-else :style="{ background: 'url('+ spliceUrl(image && image.filehash) +') center center no-repeat'}"></div>

      <div class="back" v-show="plat !== 'APP'">
        <img src="@/assets/message/white-back.png" width="20" height="20" @click="handleBack" />
      </div>

      <div class="container" v-if="mode === 'video'" :style="{ width: `${getScreenWidth}px` }">
        <div class="video-container" :style="{ marginTop: `${getMarginTop}px` }">
          <video style="width: 100%;max-height: 350px;" class="video-location" controls autoplay muted playsinline>
            <source :src="spliceUrl(video && video.filehash)" type="video/mp4" />
          </video>
        </div>
      </div>

      <div class="footer" ref="footer">
        <div @click="handleGoLP" class="explore">
          <span>{{ $t("feture.dis_now") }}</span>
          <img src="@/assets/message/right.png" width="14" height="14" />
        </div>
        <div class="content" v-if="mode === 'audio'">
          <div class="record" :class="isPlaying && 'play'" @click="handleTaggleAudio">
            <span></span><span></span><span></span><span></span><span></span>
          </div>
          <audio style="width: 100%;opacity:0;" controls="controls" :src="spliceUrl(audio && audio.filehash)" ref="audio" @ended="handleEndAudio" />
        </div>
        <div class="content" v-else>
          <h3 v-show="base.name">{{ base.name }}</h3>
          <word :word="custom.textmultiline_000" />
        </div>

        <div class="records" @click="handleGotoChain">
          <span class="left">{{ $t('feture.bc_rd') }}</span>
          <span>{{short(txId, 8, 12)}}</span>
        </div>
      </div>
    </div>

    <div class="root" v-else>
      <div class="tips" v-if="state === 'FAILURE'">
        <img src="@/assets/message/failed.png" width="160" height="128" />
        <h3>{{ $t("feture.sub_failed") }}</h3>
        <el-button
          class="btn-black-color"
          @click="handleBack"
          size="small"
          style="width: 100px;"
          v-show="plat === 'WEB'"
        >
          {{ $t("feture.fill_msg") }}
        </el-button>
      </div>
      <div class="tips" v-else-if="state === 'NULL'">
        <img src="@/assets/message/failed.png" width="160" height="128" />
        <h3>{{ $t("feture.no_data") }}</h3>
      </div>
      <div class="tips" v-else>
        <img src="@/assets/message/pass.png" width="160" height="128" />
        <h3>{{ $t("feture.chk_review") }}</h3>
      </div>
    </div>
  </div>

</template>

<script>
import Vue from 'vue';
import * as R from 'ramda';
import callApp from "@/services/sdk";
import VeTrueApi from '@/api/vetrue';
import { shortTxid } from '@/utils/bless'

import Word from '@/feature/remark/word.vue';

export default Vue.extend({
  name: 'Survey',
  components: {
    Word,
  },
  data: () => ({
    mode: "",
    vid: "",
    plat: "",
    loading: false,
    base: {},
    custom: {},
    image: {},
    video: {},
    audio: {},
    txId: '',
    state: 'SUCCESS',
    isPlaying: false, // 是否正在播放
    createUser: '',
  }),
  computed: {
    getScreenHeight() { // 获取屏幕高度
      const height = document.documentElement.clientHeight || document.body.clientHeight;
      return height;
    },
    getScreenWidth() {
      const width = document.documentElement.clientWidth || document.body.clientWidth;
      return width;
    },
    getMarginTop() { // 让视频居中显示
      const height = document.documentElement.clientHeight || document.body.clientHeight;
      const footer = this.$refs.footer; // footer 部分最大高度为300px
      const calcTop = (height - footer.clientHeight - 350) / 2;
      return calcTop;
    }
  },
  mounted() {
    this.vid = this.$route.query.vid || '';
    this.plat = this.$route.query.plat || '';
    this.getRecordDetail();

    callApp("setNavBarTitle", {
        type: "navbar.updateNavBarTitle",
        data: {
          key: "title",
          val: this.$t('feture.msg')
        }
      },
      "cbSetNavBarTitle",
      () => {},
      undefined
    );
  },
  methods: {
    // 开关音频
    handleTaggleAudio() {
      const audio = this.$refs.audio;
      if (audio.paused) { // 音频暂停
        audio.play();
        this.isPlaying = true;
      } else {
        audio.pause();
        this.isPlaying = false;
      }
    },
    handleEndAudio() { // 监控音频播放完成
      this.isPlaying = false;
    },
    short(txId, before, after) {
      return shortTxid(txId, before, after);
    },
    spliceUrl(location) {
      if (location) {
        return window.Config.bless_service_url + `/v1/public/attachment/${location}`;
      }
    },
    handleGotoChain() {
      // window.location = `https://explore.veworld.com/transactions/${this.txId}`;
      this.$router.push({
        path: '/cert',
        query:{ txId: this.txId, vid: this.vid, user: this.createUser },
      })
    },
    async getRecordDetail() {
      this.loading = true;
      try {
        const data = await VeTrueApi.getRecordDetail({ vid: this.vid });
        const state = R.pathOr({},['data', 'state'])(data);

        console.log(data, 'survey data');

        if (state === 'SUCCESS') {
          const base = R.pathOr({},['data', 'tc_dcp_data', 'dcpDataInfo', 'dcpDataInfo', 'base'])(data);
          this.base = base;

          const creator = R.pathOr('',['data', 'tc_dcp_data', 'dcpBasicInfo', 'creator'])(data);
          this.createUser = creator;

          const custom = R.pathOr({},['data', 'tc_dcp_data', 'dcpDataInfo', 'dcpDataInfo', 'custom'])(data);
          this.custom = custom;

          // 适配其他链接过来
          const video = custom.trackvideo;
          const audio = custom.mediaaudio_000;
          const image = custom.trackimages;
          const bless = custom.textmultiline_000;

          if (video && bless) {
            this.mode = 'video';
            this.video = custom.trackvideo;
          }
          if (audio && image) {
            this.mode = 'audio';
            this.audio = custom.mediaaudio_000;
            this.image = custom.trackimages[0];
          }
          if (image && bless) {
            this.mode = 'image';
            this.image = custom.trackimages[0];
          }

          // 链
          this.txId = R.pathOr('',['data', 'tc_dcp_data', 'dcpBlockchainInfo', 'txId'])(data);
        }
        this.state = state;
        this.loading = false;

      } catch (error) {
        this.loading = false;
        const { status } = error.response;
        if (status === 404) {
          this.state = 'NULL'; // 刚激活，无数据
        } else {
          this.$message({ message: this.$t('feture.failed_get_msg') + error.message, type: "error", offset: 300, duration: 4000 , customClass: 'el-message-custom'});
        }
        return;
      }
    },
    handleGoLP() {
      this.$router.push({
        path: '/remark',
        query:{ vid: this.vid, plat: this.plat },
      })
    },
    handleBack() {
      this.$router.push({
        path: '/bless',
        query:{ vid: this.vid },
      })
    },
  }
})
</script>

<style lang="scss" scoped>
.root {
  overflow: hidden;
  background-color: #FFF;
}

.tips {
  height: 100vh;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  & h3 {
    margin: 15px 0;
  }
}

.video-location {
  margin-top: 20vh;
}

.back {
  position: absolute;
  z-index: 9;
  top: 20px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.explore {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
  font-weight: 500;
  color: rgba(162, 162, 162, 1);
  & > span {
    margin-right: 5px;
  }
}

.top {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background-size: 100% 100% !important;
}

.container {
  position: absolute;
  top: -50px;
  height: 100%;
  background-color: #4A4A4A;
}

.video-container {
  width: 100%;
  height: 350px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  padding: 15px 30px 30px;
  background-color: rgba(43, 43, 43, 1);
}

@keyframes loading {
  0% {
    opacity: 0;
  }
  20% {
    opacity: .2;
  }
  40% {
    opacity: .4;
  }
  60% {
    opacity: .6;
  }
  80% {
    opacity: .8;
  }
  100% {
    opacity: 1;
  }
}

.play {
  & > span {
    animation: loading 2s 0s linear infinite;
  }
}

.record {
  position: absolute;
  left: 50%;
  margin-left: -90px;
  width: 175px;
  height: 55px;
  border-radius: 10px;
  background: linear-gradient(242deg, #00E6A4 0%, #00C86A 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  & > span {
    width: 2px;
    height: 10px;
    background-color: #FFF;
    margin-right: 6px;
    &:nth-child(even) {
      height: 15px;
    }
    &:nth-child(3){
      height: 20px;
    }
    &:last-child {
      margin: 0;
    }
  }
}

.content {
  font-size: 15px;
  padding: 30px 0;
  color: rgba(255, 255, 255, 1);
  & > h3 {
    margin-bottom: 10px;
  }
}

.records {
  font-size: 12px;
  color: #3B3B3B;
  display: flex;
  border: 1px solid #EFEFEF;
  background-color: #F6F6F6;
  border-radius: 20px;
  & > span {
    text-align: center;
    padding: 10px 5px;
    border-radius: 20px;
  }
}
.left {
  padding: 10px 10px !important;
  font-weight: 600;
  background-color: #FFF;
}
</style>
